import React from "react";

interface Props extends React.HTMLProps<HTMLDivElement> {
  textClass?: string;
}


const kRestPath =
  "M333.885 363.395C333.885 363.395 333.619 363.395 333.088 363.395C324.985 363.395 318.21 359.542 312.763 351.838L258.564 274.325C255.243 269.543 250.727 267.019 245.014 266.753C239.435 266.62 234.719 268.812 230.867 273.329H230.468V333.705C230.468 341.941 227.546 348.982 221.701 354.827C215.856 360.672 208.948 363.528 200.978 363.395H200.778C192.542 363.395 185.502 360.539 179.657 354.827C173.812 348.982 170.889 341.941 170.889 333.705V143.211C170.889 135.241 173.812 128.333 179.657 122.488C185.502 116.643 192.542 113.721 200.778 113.721H200.978C208.948 113.721 215.856 116.643 221.701 122.488C227.546 128.333 230.468 135.241 230.468 143.211V186.451C230.468 190.303 231.597 192.562 233.856 193.226C235.981 194.023 237.642 193.558 238.837 191.831L285.863 126.872C290.778 119.3 297.686 115.514 306.586 115.514H307.184C317.014 115.514 324.187 119.831 328.704 128.466C333.221 137.101 332.955 145.536 327.907 153.772L299.213 199.801C290.712 213.484 290.712 226.834 299.213 239.853L354.807 324.937C360.121 333.174 360.387 341.609 355.604 350.244C350.822 359.011 343.582 363.395 333.885 363.395Z";
const kTransitionPath = "";

const PageIcon: React.FC<Props> = ({ href, textClass, ...props }) => {
  return (
    <div style={{ backgroundColor: "#1F1C24", borderRadius: "20%" }} {...props} >
      <svg
        width="512"
        fill="none"
        height="512"
        viewBox="0 0 512 512"
        className="inline-block h-full w-auto"
      >
        <path d={`${kRestPath}`} fill="white">
          <animate
            dur="5s"
            values=""
            fill="freeze"
            attributeName="d"
            calcMode="spline"
            repeatCount="indefinite"
            keySplines="0.4 0 0.2 1; 0.4 0 0.2 1"
          ></animate>
        </path>
      </svg>
    </div>
  );
};

export default PageIcon;
